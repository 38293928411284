<template>
    <div class="offline-info-container">
        <div class="offline-info-tools">
            <el-form class="battery-form" :inline="true" :model="formData">
                <el-form-item label="电池ID">
                    <el-input v-model="formData.batteryCode" placeholder="电池ID" size="small" class="area-form-item" style="width: 180px;">
                    </el-input>
                </el-form-item>
                <el-form-item label="消息类型">
                    <el-select v-model="formData.type" placeholder="消息类型" size="small" class="area-form-item">
                        <el-option label="全部" :value="''"></el-option>
                        <el-option label="当前离线" :value="1"></el-option>
                        <el-option label="历史离线" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="消息时间">
                    <el-date-picker
                        v-model="formData.msgTime"
                        size="small"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item style="float: right; marginRight: 0px;">
                    <el-button type="primary" @click="getData(1)">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
         <div class="offline-info-content">
            <el-table 
                height="100%"
                :data="list"
            >
                <el-table-column label="消息类型" prop="alarmCodeName">
                    <template slot-scope="{ row }"> 
                        {{ row.recoveryTime ? '历史离线' : '当前离线' }}
                    </template>
                </el-table-column>
                <el-table-column label="电池ID" prop="batteryCode"></el-table-column>
                <el-table-column label="最后数据时间" prop="reportTime"></el-table-column>
                <el-table-column label="恢复时间" prop="recoveryTime"></el-table-column>
                <el-table-column label="离线时长（小时）" prop="unLineTimes"></el-table-column>
            </el-table>
        </div>
        <div class="offline-page-container">
            <div class="offline-page-content">
                <el-pagination
                    background
                    :current-page="page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/utils/axios';
import moment from 'moment';
export default {
    name: 'OffLineInfo',
    data() {
        return {
            formData: {
                type: '',
                msgTime: '',
                batteryCode: ''
            },
            page: 1,
            pageSize: 10,
            list: [],
            total: 0
        }
    },
    methods: {
        getData(_page) {
            const {
                page,
                pageSize
            } = this;
            const {
                type,
                msgTime,
                batteryCode
            } = this.formData;
            let startTimeStr = '';
            let endTimeStr = '';
            if (msgTime) {
                const [startTime, endTime] = msgTime;
                startTimeStr = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
                endTimeStr = moment(endTime).format('YYYY-MM-DD HH:mm:ss');
            }
            axios.get(`/api/batteryAlarmHistory/pageList2/${_page ? _page : page}/${pageSize}`, {
                startTime: startTimeStr,
                endTime: endTimeStr,
                type,
                batteryCode
            }).then(rs => {
                const { records, total, page } = rs;
                this.list = records;
                this.total = total;
                this.page = page;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getData();
        },
    },
     mounted() {
        this.getData();
    }
}
</script>

<style lang="less">
    .offline-info-container {
        height: 100%;
        .offline-info-tools {
            height: 80px;
            padding: 0 30px;
            line-height: 100%;
        }
        .offline-info-content {
            overflow: hidden;
            height: calc(100% - 80px - 98px);
            width: calc(100% - 60px);
            padding: 0 30px;
        }
        .offline-page-container {
            position: relative;
            height: 98px;
            padding: 0 31px;
            .offline-page-content {
                position: relative;
                top: 21px;
                height: 56px;
                background: #F5F5F5;
                .el-pagination {
                    position: absolute;
                    top: 12px;
                    right: 63px
                }
            }
        }
    }
</style>